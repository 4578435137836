import _ from "lodash"
import React from "react"
import { useMediaQuery } from "react-responsive"

import {
  Container,
  Header,
  Segment,
  Grid,
  Responsive,
  Label,
  Item,
} from "semantic-ui-react"
import ResponsiveLayout from "../components/responsive-layout"
import algoliasearch from "algoliasearch/lite"

import "../components/styles.css"
import "react-responsive-carousel/lib/styles/carousel.min.css"

import PageContent from "frontmatter-markdown-loader?modules!../../content/home.md"

const algoliaSearchClient = algoliasearch("3RYLE0NWFZ", "ec2bb813abe0f299b6ffb2c783002865")

export default ({ data = PageContent.attributes }) => (
  <ResponsiveLayout title={data.title}>
    <Page content={data} />
  </ResponsiveLayout>
)

const Page = () => {
  return (
    <Container>
      <Segment basic style={{marginTop: "2em"}}>
        <SearchResults />
      </Segment>
    </Container>
  )
}

class SearchResults extends React.Component {
  state = {
    isLoading: true,
    searchResults: null,
  }
  componentDidMount() {
    const searchQuery = new URLSearchParams(window.location.search).get("q")
    algoliaSearchClient.multipleQueries([
      {
        indexName: "maps_and_figures",
        query: searchQuery,
      },
      {
        indexName: "briefs_and_fact_sheets",
        query: searchQuery,
      },
      {
        indexName: "trends_figures_and_tables",
        query: searchQuery,
      },
      {
        indexName: "cases_and_case_resources",
        query: searchQuery,
      },
      {
        indexName: "pages_and_sections",
        query: searchQuery,
      },
    ]).then(({ results }) => {
      console.log(results)
      this.setState({
        isLoading: false,
        searchResults: results
      })
    })
  }
  render() {
    return this.state.isLoading ? <p> Loading...</p> :
    <Grid divided stackable>
      <Grid.Column width={11}>
        <Segment basic style={{ marginTop: "2em", padding: "0em" }}>
          <Header as="h3" dividing>
            Maps and Figures
          </Header>
          {
            this.state.searchResults[0].hits.length ?
              <Item.Group divided relaxed style={{ marginTop: "2em" }}
                content={
                  this.state.searchResults[0].hits.map((hit, i) =>
                    <Item>
                      <a target="_blank" href={hit.url}
                        style={{
                          textAlign: "center"
                        }}
                        children={
                          <Item.Image bordered spaced size="small"
                            src={hit.image}
                          />
                        }
                      />
                      <Item.Content verticalAlign="middle">
                        <Label basic as="a" icon="external square"
                          href={hit.url} target="_blank"
                          style={{ fontSize: "1em", fontWeight: "unset", border: "none" }}
                          content={hit.title}
                        />
                      </Item.Content>
                    </Item>
                  )
                }
              />
            : <p>No matched results...</p>
          }
        </Segment>
        <Segment basic style={{ marginTop: "3em", padding: "0em" }}>
          <Header as="h3" dividing>
            Briefs and Fact Sheets
          </Header>
          {
            this.state.searchResults[1].hits.length ?
              <Item.Group divided relaxed style={{ marginTop: "2em" }}
                content={
                  this.state.searchResults[1].hits.map((hit, i) =>
                    <Item>
                      <a target="_blank" href={hit.url}
                        style={{
                          textAlign: "center"
                        }}
                        children={
                          <Item.Image bordered spaced size="small"
                            src={hit.image}
                          />
                        }
                      />
                      <Item.Content verticalAlign="middle">
                        <Label basic as="a" icon="external square"
                          href={hit.url} target="_blank"
                          style={{ fontSize: "1em", fontWeight: "unset", border: "none" }}
                          content={hit.title}
                        />
                      </Item.Content>
                    </Item>
                  )
                }
              />
            : <p>No matched results...</p>
          }
        </Segment>
        <Segment basic style={{ marginTop: "3em", padding: "0em" }}>
          <Header as="h3" dividing>
            Trends, Figures and Tables
          </Header>
          {
            this.state.searchResults[2].hits.length ?
              <Item.Group divided relaxed style={{ marginTop: "2em" }}
                content={
                  this.state.searchResults[2].hits.map((hit, i) =>
                    <Item>
                      <a target="_blank" href={hit.url}
                        style={{
                          textAlign: "center"
                        }}
                        children={
                          <Item.Image bordered spaced size="small"
                            src={hit.image}
                          />
                        }
                      />
                      <Item.Content verticalAlign="middle">
                        <Label basic as="a" icon="external square"
                          href={hit.url} target="_blank"
                          style={{ fontSize: "1em", fontWeight: "unset", border: "none" }}
                          content={hit.title}
                        />
                      </Item.Content>
                    </Item>
                  )
                }
              />
            : <p>No matched results...</p>
          }
        </Segment>
      </Grid.Column>
      <Grid.Column width={5}>
        <Segment basic style={{ marginTop: "2em", padding: "0em" }}>
          <Header as="h3" dividing>
            Cases and Case Resources
          </Header>
          {
            this.state.searchResults[3].hits.filter(hit => hit.url).length ?
              this.state.searchResults[3].hits.filter(hit => hit.url).map(hit =>
                <Label basic as="a" icon="external square"
                  href={hit.url} target="_blank"
                  style={{ fontSize: "1em", fontWeight: "unset", border: "none", padding: "0.8em" }}
                  content={hit.title}
                />
              )
            : <p>No matched results...</p>
          }
        </Segment>
        <Segment basic style={{ marginTop: "2em", padding: "0em" }}>
          <Header as="h3" dividing>
            Pages and Sections
          </Header>
          {
            this.state.searchResults[4].hits.length ?
              this.state.searchResults[4].hits.map(hit =>
                <Label basic as="a" icon="external square"
                  href={hit.url} target="_blank"
                  style={{ fontSize: "1em", fontWeight: "unset", border: "none", padding: "0.8em" }}
                  content={hit.title}
                />
              )
            : <p>No matched results...</p>
          }
        </Segment>
      </Grid.Column>
    </Grid>
  }
}
